// Header animations
const Header = document.querySelector('.Header:not(.Header--no-scroll)');

Header && window.addEventListener('scroll', () => {
  const top = (window.pageYOffset
    || document.documentElement.scrollTop) - (document.documentElement.clientTop || 0
  );

  if (top >= 30) {
    Header.classList.add('fixed');
  } else {
    Header.classList.remove('fixed');
  }
});
