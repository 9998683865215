const dropdownBtn = Array.prototype.slice.call(document.querySelectorAll('[data-dropdown-btn]'));
const dropdownBox = Array.prototype.slice.call(document.querySelectorAll('[data-dropdown-box]'));
const mql = window.matchMedia('(max-width: 979px)');


if (dropdownBtn && dropdownBox && mql.matches) {
  dropdownBtn.forEach((el, index) => {
    el.addEventListener('click', function () {
      dropdownBox[index].classList.toggle('open');
      this.classList.toggle('active');
    });
  });
}
