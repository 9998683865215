(function (window, document) {
  function init() {
    window.NotifyWidget({
      container: '.NotifyWidget',
      siteId: 65,
      visible: [980, 1980],
    });

    window.NotifyWidget({
      container: '.NotifyWidget2',
      siteId: 65,
      type: 'popup',
      visible: [0, 980],
    });
  }

  if (typeof window.NotifyWidget !== 'undefined') {
    init();
  } else {
    const script = document.createElement('script');
    script.src = '/assets/js/notifyWidget.min.js';
    script.async = true;
    script.onload = function () {
      init();
    };
    document.getElementsByTagName('head')[0].appendChild(script);
  }
}(window, document));
