// Toggle block
const toggleBlock = (
  el,
  container,
  classes,
  options = { overlay: true, scrollBlock: true }
) => {
  const overlay = document.querySelector(".Overlay");
  const header = document.querySelector(".Header__main");

  if (el) {
    el.addEventListener("click", () => {
      if (container.classList.contains(classes[0])) {
        el.classList.remove(classes[1]);
        header.classList.remove("Header__main--open");
        container.classList.remove(classes[0]);
        if (options.overlay) overlay.classList.remove("show");
        if (options.scrollBlock) {
          document
            .getElementsByTagName("body")[0]
            .classList.remove("no-scroll");
          document
            .getElementsByTagName("html")[0]
            .classList.remove("no-scroll");
        }
      } else {
        el.classList.add(classes[1]);
        header.classList.add("Header__main--open");
        container.classList.add(classes[0]);
        if (options.overlay) overlay.classList.add("show");
        if (options.scrollBlock) {
          document.getElementsByTagName("body")[0].classList.add("no-scroll");
          document.getElementsByTagName("html")[0].classList.add("no-scroll");
        }
      }
    });
  }
};

// Toggle menu
const hamburger = document.querySelector(".ToggleMenu");
const menu = document.querySelector(".Menu__wrapper");

toggleBlock(hamburger, menu, ["visible", "is-active"]);
