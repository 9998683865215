import '../../components/Calculator/CalculatorDefault';
import '../../components/ImagesLazyLoading';
import './dropdown';
import './headerScroll';
import './samples';
import './scrollTo';
import './testimonials';
import './toggleMenu';

window.addEventListener('load', () => {
  const hrefElements = document.querySelectorAll('[data-href]');
  if (hrefElements) {
    Array.prototype.slice.call(hrefElements).forEach((el) => {
      el.addEventListener('click', (e) => {
        const destinationOffset = document.getElementById(e.target.dataset.href).offsetTop - 40;
        window.scroll({
          behavior: 'smooth',
          left: 0,
          top: destinationOffset,
        });
      });
    });
  }
});
