import { observeElement, getWidget } from '@uvocore-front/utils';

/* Calculator widget */
// eslint-disable-next-line no-shadow
const isPageConfig = window.pageConfig
  && Object.keys(window.pageConfig).length > 0
  && window.pageConfig.constructor === Object;

if (document.querySelector('[data-calc-mini]')) {
  observeElement({
    element: '[data-calc-mini]',
    marginValue: 20,
    callback: () => {
      getWidget({
        widgetLink: '/assets/js/calc.js',
        loader: false,
        fn: () => {
          const pagePaperType = isPageConfig
            && window.pageConfig.calculator !== undefined
            && window.pageConfig.calculator.hasOwnProperty('pagePaperType') /* eslint-disable-line */
            ? window.pageConfig.calculator.pagePaperType
            : undefined;

          const calcOptions = {
            ID: '65',
            calculatorType: 'DefaultCalculator',
            parent: '[data-calc-mini]',
            format: 'json',
            options: {
              deafault: {
                deadlinesDefault: [137275],
                academicLevelDefault: 1,
                pagesDefault: 1,
              },
            },
          };

          if (pagePaperType !== undefined) {
            calcOptions.options.deafault.paperTypesDefault = pagePaperType;
          }

          if (window.Calc) {
            const calc = new Calc(calcOptions); /* eslint-disable-line */
          }
        },
      });
    },
  });
}
