import { observeElement } from '@uvocore-front/utils';
import { tns } from '../../../node_modules/tiny-slider/src/tiny-slider';

observeElement({
  element: '.Testimonials',
  marginValue: 400,
  callback: () => {
    tns({
      container: '.Testimonials',
      items: 2,
      slideBy: 'page',
      loop: false,
      gutter: 25,
      controls: false,
      mouseDrag: true,
      arrowKeys: true,
      controlsText: ['', ''],
      responsive: {
        320: {
          items: 1,
        },
        680: {
          items: 2,
        },
        940: {
          items: 2,
        },
        1000: {
          items: 2,
        },
      },
    });
  },
});
